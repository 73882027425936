'use client';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import { Input } from '@/components/shared/material';
import { InputProps } from '@material-tailwind/react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { MagnifyingGlass } from '@phosphor-icons/react';

type AutocompleteInputPropsExtended = InputProps & {
  addressType: 'delivery' | 'billing';
};

export const AutoCompleteField = ({
  ...props
}: FieldHookConfig<any> & AutocompleteInputPropsExtended) => {
  const formikProps = useFormikContext();
  const [field, meta] = useField(props);

  const setLocalStorage = (value: string) => {
    const storedAddress = localStorage.getItem(`${props.addressType}Form`);
    const parsedAddress = storedAddress ? JSON.parse(storedAddress) : {};

    localStorage.setItem(
      `${props.addressType}Form`,
      JSON.stringify({
        ...parsedAddress,
        [`${props.name}`]: value,
      })
    );
  };

  const { ref } = usePlacesWidget({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_PLACES_API_KEY || '',
    options: {
      componentRestrictions: { country: 'za' },
      fields: ['address_components'],
      types: ['address'],
    },
    onPlaceSelected: (place) => {
      let streetNumber = '';
      let streetName = '';
      let city = '';
      let province = '';
      let postCode = '';
      let suburb = '';

      place?.address_components?.forEach((component, index) => {
        component.types.forEach((type) => {
          switch (type) {
            case 'street_number':
              streetNumber = `${component.long_name}`;
              break;
            case 'route':
              streetName = `${component.long_name}`;
              break;
            case 'sublocality':
            case 'sublocality_level_1':
              suburb = component.long_name;
              formikProps.setFieldValue(
                `${props.addressType}Suburb`,
                component.long_name
              );
              break;

            case 'locality':
              city = component.long_name;
              formikProps.setFieldValue(
                `${props.addressType}City`,
                component.long_name
              );
              break;
            case 'administrative_area_level_1':
              province = component.long_name;
              formikProps.setFieldValue(
                `${props.addressType}Province`,
                component.long_name
              );
              break;
            case 'postal_code':
              postCode = component.long_name;
              formikProps.setFieldValue(
                `${props.addressType}PostCode`,
                component.long_name
              );
              break;
          }
        });

        if (
          place?.address_components?.length &&
          index === place?.address_components?.length - 1
        ) {
          // Reset the input field
          const inputElement = ref.current as HTMLInputElement | null;
          if (inputElement) inputElement.value = '';

          localStorage.setItem(
            `${props.addressType}Form`,
            JSON.stringify({
              deliveryStreet: `${streetNumber} ${streetName}`,
              deliverySuburb: suburb,
              deliveryCity: city,
              deliveryProvince: province,
              deliveryPostCode: postCode,
            })
          );
        }
      });

      formikProps.setFieldValue(
        `${props.addressType}Street`,
        `${streetNumber} ${streetName}`
      );
    },
  });

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    formikProps.setFieldTouched(field.name, true, true);
    setLocalStorage(e.target.value);
  };

  return (
    <>
      <Input
        {...field}
        icon={<MagnifyingGlass className='text-xl' />}
        //@ts-ignore
        iconPosition='right'
        onBlur={onBlur}
        label={props.label}
        name={props.name}
        maxLength={256}
        variant='standard'
        required={props.required}
        error={meta.touched && !!meta.error}
        labelProps={props.labelProps}
        type='text'
        inputRef={ref as any}
        containerProps={{
          className: '!h-[56px]',
        }}
        className='input placeholder:opacity-0 focus:placeholder:opacity-100'
      />
      <div className='-mb-4 flex items-center justify-between gap-2 pb-0.5 pl-2 pt-1 text-xs text-gray-400'>
        <span>
          {meta.touched && meta.error ? (
            <div className='mr-2 inline text-xs text-red-500'>
              {meta.error + ' '}
            </div>
          ) : null}
          Eg. 12 Ridge Street
        </span>

        <div className='flex items-center gap-2 text-gray-500'>
          <svg
            className='h-4 w-4 fill-gray-500'
            fill='none'
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
            <g
              id='SVGRepo_tracerCarrier'
              strokeLinecap='round'
              strokeLinejoin='round'
            ></g>
            <g id='SVGRepo_iconCarrier'>
              <path d='M473.16,221.48l-2.26-9.59H262.46v88.22H387c-12.93,61.4-72.93,93.72-121.94,93.72-35.66,0-73.25-15-98.13-39.11a140.08,140.08,0,0,1-41.8-98.88c0-37.16,16.7-74.33,41-98.78s61-38.13,97.49-38.13c41.79,0,71.74,22.19,82.94,32.31l62.69-62.36C390.86,72.72,340.34,32,261.6,32h0c-60.75,0-119,23.27-161.58,65.71C58,139.5,36.25,199.93,36.25,256S56.83,369.48,97.55,411.6C141.06,456.52,202.68,480,266.13,480c57.73,0,112.45-22.62,151.45-63.66,38.34-40.4,58.17-96.3,58.17-154.9C475.75,236.77,473.27,222.12,473.16,221.48Z'></path>
            </g>
          </svg>
          Powered by Google
        </div>
      </div>
    </>
  );
};

export default AutoCompleteField;
